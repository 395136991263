import { Button, Drawer, List, ListItemButton, ListItemText, ListSubheader, Stack } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "../../firebase";
import { getDatabase, ref, set, push } from "firebase/database";
import { Exhibition } from "../..";
import Editor from "./Editor";
import useExhibitions from "../../useExhibitions";
import Contacts from "./Contacts";
import useContacts from "../../useContacts";

export default function Admin() {
  const navigate = useNavigate();
  const exhibitions = useExhibitions();

  const [isLoading, setIsLoading] = useState(true);
  const [isContacts, setIsContacts] = useState(true);
  const [selected, setSelected] = useState<Exhibition | null>(null);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) setIsLoading(false);
      else navigate("/login");
    });
  }, [navigate]);

  async function createExhibition() {
    const db = getDatabase(app);
    const exhibitionsRef = ref(db, "exhibitions");
    const newExhibitionRef = push(exhibitionsRef);

    set(newExhibitionRef, {
      shortTitle: "Nuova esibizione",
      longTitle: "",
      thumbnail: "",
      description: "",
      gallery: {
        height: 300,
        columns: 3,
        items: JSON.stringify([]),
      },
    });
  }

  useEffect(() => {
    setIsContacts(true);
    if (exhibitions.length) setSelected(null);
  }, [exhibitions.length]);

  const contacts = useContacts();

  if (isLoading) return null;

  return (
    <>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            top: 100,
            width: 240,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          subheader={<ListSubheader component="div">Generali</ListSubheader>}>
          <ListItemButton
            selected={isContacts}
            onClick={() => {
              setIsContacts(true);
              setSelected(null);
            }}>
            <ListItemText primary={"Contatti"} />
          </ListItemButton>
        </List>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", paddingBottom: "100px" }}
          component="nav"
          subheader={
            <Stack direction="row" justifyContent={"space-between"}>
              <ListSubheader component="div">Esibizioni</ListSubheader>
              <Button onClick={() => createExhibition()}>Aggiungi</Button>
            </Stack>
          }>
          {exhibitions.map((exhibition) => (
            <ListItemButton
              key={exhibition.id}
              selected={selected?.id === exhibition.id}
              onClick={() => {
                setSelected(exhibition);
                setIsContacts(false);
              }}>
              <ListItemText primary={exhibition.shortTitle} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <Stack style={{ marginTop: 100, marginLeft: 240 }}>
        {isContacts && contacts ? <Contacts contacts={contacts} /> : null}
        {selected ? <Editor key={selected?.id} exhibition={selected} /> : null}
      </Stack>
    </>
  );
}
