import { Button, Stack, TextField, Typography } from "@mui/material";
import { getDatabase, ref, set } from "firebase/database";
import { useState } from "react";
import { app } from "../../firebase";
import type { Contacts as IContacts } from "../../useContacts";

export default function Contacts({ contacts }: { contacts: IContacts }) {
  const [mail, setmail] = useState(contacts?.mail || "");
  const [facebook, setfacebook] = useState(contacts?.facebook || "");
  const [instagram, setinstagram] = useState(contacts?.instagram || "");
  const [primaryPhoneName, setprimaryPhoneName] = useState(contacts?.primaryPhoneName || "");
  const [primaryPhoneNumber, setprimaryPhoneNumber] = useState(contacts?.primaryPhoneNumber || "");
  const [secondaryPhoneName, setsecondaryPhoneName] = useState(contacts?.secondaryPhoneName || "");
  const [secondaryPhoneNumber, setsecondaryPhoneNumber] = useState(contacts?.secondaryPhoneNumber || "");

  return (
    <Stack p={2} gap={2}>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            const db = getDatabase(app);
            const contactsRef = ref(db, `contacts`);
            set(contactsRef, {
              mail,
              facebook,
              instagram,
              primaryPhoneName,
              primaryPhoneNumber,
              secondaryPhoneName,
              secondaryPhoneNumber,
            });
          }}>
          Salva
        </Button>
      </div>
      <Typography>Mail</Typography>
      <TextField value={mail} onChange={(e) => setmail(e.target.value)} />
      <Typography>Telefono primario</Typography>
      <Stack gap={2} direction="row">
        <TextField
          value={primaryPhoneName}
          onChange={(e) => setprimaryPhoneName(e.target.value)}
          style={{ flexGrow: 1 }}
          label="Nome"
        />
        <TextField
          value={primaryPhoneNumber}
          onChange={(e) => setprimaryPhoneNumber(e.target.value)}
          style={{ flexGrow: 1 }}
          label="Numero"
        />
      </Stack>
      <Typography>Telefono secondario</Typography>
      <Stack gap={2} direction="row">
        <TextField
          value={secondaryPhoneName}
          onChange={(e) => setsecondaryPhoneName(e.target.value)}
          style={{ flexGrow: 1 }}
          label="Nome"
        />
        <TextField
          value={secondaryPhoneNumber}
          onChange={(e) => setsecondaryPhoneNumber(e.target.value)}
          style={{ flexGrow: 1 }}
          label="Numero"
        />
      </Stack>
      <Typography>Facebook</Typography>
      <TextField value={facebook} onChange={(e) => setfacebook(e.target.value)} />
      <Typography>Instagram</Typography>
      <TextField value={instagram} onChange={(e) => setinstagram(e.target.value)} />
    </Stack>
  );
}
