import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function SectionTitle({ children }: { children: ReactNode }) {
  return (
    <Stack alignItems="center" style={{ marginBottom: "64px", position: "relative", zIndex: 1 }}>
      <Stack alignItems="center" gap={1}>
        <Typography style={{ textTransform: "uppercase", color: "black", fontSize: "3.438rem" }}>{children}</Typography>
        <span style={{ width: "50%", height: 1, background: "black" }} />
      </Stack>
    </Stack>
  );
}
