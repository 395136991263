import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { app } from "./firebase";

export interface Contacts {
  mail: string;
  facebook: string;
  instagram: string;
  primaryPhoneName: string;
  primaryPhoneNumber: string;
  secondaryPhoneName: string;
  secondaryPhoneNumber: string;
}
export default function useContacts() {
  const [contacts, setContacts] = useState<Contacts | null>(null);

  useEffect(() => {
    const db = getDatabase(app);
    const exhibitionsRef = ref(db, "contacts");
    onValue(exhibitionsRef, (snapshot) => {
      const data = snapshot.val();
      setContacts(data);
    });
  }, []);

  return contacts;
}
