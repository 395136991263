import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Contacts from "../../components/Contacts/Contacts";
import Exhibitions from "./Exhibitions/Exhibitions";
import Home from "./Home/Home";
import Services from "./Services/Services";

export default function Root() {
  const [fromChild, setFromChild] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      setFromChild(true);
    }
  }, [location.pathname, fromChild]);

  useEffect(() => {
    if (!fromChild) return;

    let interval: NodeJS.Timer | null = null;
    let timeout: NodeJS.Timer | null = null;

    if (location.pathname !== "/") {
      if (interval) clearInterval(interval);
      return;
    }

    interval = setInterval(() => {
      const id = localStorage.getItem("route");
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          localStorage.removeItem("route");
          setFromChild(false);
        }
      }
    }, 100);

    timeout = setTimeout(() => {
      if (interval) clearInterval(interval);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
  }, [location.pathname, fromChild]);

  return (
    <>
      <Home />
      <Services />
      <Exhibitions />
      <div style={{ position: "relative" }}>
        <Contacts />
        <div style={{ background: "#ffb200", height: 66, width: "100%", position: "absolute", bottom: -65 }} />
      </div>
    </>
  );
}
