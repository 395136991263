import { Stack, styled, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery } from "@mui/material";
import { wrapper } from "../../theme";
import SectionTitle from "../../routes/Root/SectionTitle";
import facebook from "./facebook.webp";
import instagram from "./instagram.webp";
import useContacts from "../../useContacts";

export default function Contacts({ title = true }: { title?: boolean }) {
  const isMobile = useMediaQuery("(max-width:820px)");
  const contacts = useContacts();

  const video = (
    <video
      src={"/combinazioni.mp4"}
      autoPlay
      muted
      loop
      playsInline
      style={{
        mixBlendMode: "multiply",
      }}
    />
  );
  return (
    <Stack
      id="contacts"
      sx={{
        alignItems: "center",
        paddingTop: isMobile ? "64px" : "100px",
        marginTop: "-64px",
        paddingBottom: isMobile ? 0 : "64px",
        marginBottom: 8,
        background: "#ffb200",
      }}>
      {title ? <SectionTitle>Contatti</SectionTitle> : null}
      <Stack sx={{ width: wrapper }}>
        <Stack
          sx={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "space-between",
            flexWrap: "wrap",
            position: "relative",
          }}>
          <Typography sx={{ color: "black", fontSize: "1.375rem", fontWeight: "bold", width: "100%" }}>
            Se interessati invio informazioni dettagliate.
          </Typography>
          <div style={{ width: isMobile ? "100%" : "50%", marginTop: 48 }}>
            <Table>
              <TableBody>
                <Row>
                  <TableCell padding="none">
                    <Text>Mail:</Text>
                  </TableCell>
                  <TableCell padding="none" sx={{ paddingLeft: 1 }}>
                    <Text>
                      <a href={`mailto:${contacts?.mail}`}>{contacts?.mail}</a>
                    </Text>
                  </TableCell>
                </Row>
                <Row>
                  <TableCell padding="none" sx={{ paddingTop: 4 }}>
                    <Text>Tel:</Text>
                  </TableCell>
                  <TableCell padding="none" sx={{ paddingTop: 4, paddingLeft: 1 }}>
                    <Text>
                      {contacts?.primaryPhoneName}{" "}
                      <a
                        href={`tel:+39 ${contacts?.primaryPhoneNumber || "".replace("+39", "").replaceAll(" ", "")}`}
                        style={{ whiteSpace: "nowrap" }}>
                        {contacts?.primaryPhoneNumber}
                      </a>
                    </Text>
                  </TableCell>
                </Row>
                <Row>
                  <TableCell></TableCell>
                  <TableCell padding="none" sx={{ paddingLeft: 1 }}>
                    <Text>
                      {contacts?.secondaryPhoneName}{" "}
                      <a
                        href={`tel:+39 ${contacts?.secondaryPhoneNumber || "".replace("+39", "").replaceAll(" ", "")}`}
                        style={{ whiteSpace: "nowrap" }}>
                        {contacts?.secondaryPhoneNumber}
                      </a>
                    </Text>
                  </TableCell>
                </Row>
                <Row>
                  <TableCell></TableCell>
                  <TableCell padding="none" sx={{ paddingTop: 4, paddingLeft: 1 }}>
                    <Text>
                      <a href="https://goo.gl/maps/uw8YBzaYMCU952SJ7" target="_blank" rel="noreferrer">
                        Via T. A. Edison 53, Trezzano Sul Naviglio, Milano
                      </a>
                    </Text>
                  </TableCell>
                </Row>

                <Row>
                  <TableCell></TableCell>
                  <TableCell padding="none" sx={{ paddingTop: 6 }}>
                    <Stack gap={4} sx={{ flexDirection: "row" }}>
                      <a href={contacts?.facebook} target="_blank" rel="noreferrer">
                        <img src={facebook} alt="" />
                      </a>
                      <a href={contacts?.instagram} target="_blank" rel="noreferrer">
                        <img src={instagram} alt="" />
                      </a>
                    </Stack>
                  </TableCell>
                </Row>
              </TableBody>
            </Table>
            {isMobile ? (
              <Stack
                style={{
                  margin: "0 auto",
                  width: "90%",
                }}>
                {video}
              </Stack>
            ) : null}
            <Typography sx={{ marginTop: isMobile ? 2 : 12, opacity: 0.7 }}>© 2022 by Gazesystem</Typography>
          </div>
          {!isMobile ? (
            <Stack
              style={{
                width: "40%",
                position: "absolute",
                top: -80,
                right: 0,
              }}>
              {video}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}

const Row = styled(TableRow)({
  "& td": {
    border: 0,
  },
});

const Text = styled(Typography)({
  color: "black",
  fontSize: "1.375rem",
  verticalAlign: "initial",
  "& a": {
    color: "black",
    textDecoration: "underline",
  },
});
