import { Button, Stack, TextField, FormHelperText, Typography, ImageList, ImageListItem } from "@mui/material";
import { getDatabase, ref as dbRef, set, remove } from "firebase/database";
import { getDownloadURL, getStorage, ref as stRef, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { useState } from "react";
import { Exhibition, GalleryItem } from "../..";
import { app } from "../../firebase";
import { wrapper } from "../../theme";

export default function Editor({ exhibition }: { exhibition: Exhibition }) {
  const [isSaving, setIsSaving] = useState(false);
  const [longTitle, setLongTitle] = useState(exhibition.longTitle);
  const [shortTitle, setShortTitle] = useState(exhibition.shortTitle);
  const [description, setDescription] = useState(exhibition.description);
  const [thumbnail] = useState(exhibition.thumbnail);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);

  const [height, setHeight] = useState(exhibition.gallery.height);
  const [columns, setColumns] = useState(exhibition.gallery.columns);
  const [galleryItems, setGalleryItems] = useState<GalleryItem[]>(exhibition.gallery.items);
  const [files, setFiles] = useState<Record<string, File>>({});

  async function save() {
    const db = getDatabase(app);
    const exhibitionRef = dbRef(db, `exhibitions/${exhibition.id}`);

    setIsSaving(true);
    const thumbnail = thumbnailFile ? await uploadImage("thumbnail", thumbnailFile) : exhibition.thumbnail;

    const sources: { id: string; img: string }[] = [];
    const filesToUpload = Object.keys(files).map((id) => ({ id, data: files[id] }));
    for (const file of filesToUpload) {
      const img = await uploadImage(file.id, file.data);
      sources.push({ id: file.id, img });
    }

    const ngalleryItems = galleryItems.map((item) => ({
      ...item,
      img: sources.find((src) => src.id === item.id)?.img || item.img,
    }));

    set(exhibitionRef, {
      shortTitle,
      longTitle,
      description: description.replaceAll("\n", "<br />"),
      thumbnail,
      gallery: {
        height,
        columns,
        items: JSON.stringify(ngalleryItems),
      },
    }).then(() => {
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    });
  }

  function uploadImage(id: string, file: File | null): Promise<string> {
    return new Promise((resolve) => {
      if (!file) {
        resolve("");
        return;
      }

      const storage = getStorage(app);
      const storageRef = stRef(storage, `${exhibition.id}/${id}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "success":
              console.log("Upload success");
              break;
          }
        },
        (error) => {
          console.error(error);
          alert("Errore nel caricamento dell'immagine, riprova. se il problema persiste contattare vincenzo florio");
          resolve("");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  function removeExhibition() {
    const db = getDatabase(app);
    const exhibitionRef = dbRef(db, `exhibitions/${exhibition.id}`);
    remove(exhibitionRef);
  }

  const updateGalleryItem = (index: number, next: GalleryItem) => {
    setGalleryItems((itemData) => itemData.map((current, currentIndex) => (index === currentIndex ? next : current)));
  };

  const removeGalleryItem = (index: number) =>
    setGalleryItems((itemData) => itemData.filter((_, currentIndex) => currentIndex !== index));

  const updateGalleryFile = (id: string, file: File) => {
    setFiles((files) => ({
      ...files,
      [id]: file,
    }));
  };

  return (
    <Stack p={2} gap={2} position="relative" pt={8}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        pb={1}
        pt={1}
        position="fixed"
        width="calc(100% - 240px - 32px - 16px)"
        top={100}
        left={240 + 16}
        zIndex={10}
        sx={{ background: "white" }}>
        <Button variant="contained" onClick={save} disabled={isSaving}>
          {isSaving ? "Salvataggio in corso, non chiudere la pagina..." : "Salva"}
        </Button>
        <Button onClick={removeExhibition}>Elimina</Button>
      </Stack>
      <Typography variant="h5">Metadati</Typography>
      <Typography>Titolo abbreviato *</Typography>
      <TextField
        value={shortTitle}
        onChange={(event) => setShortTitle(event.target.value)}
        helperText="Titolo che appare nel menù della barra di navigazione e come intestazione della pagina dedicata all'esibizione"
      />
      <Typography>Titolo completo *</Typography>
      <TextField
        value={longTitle}
        onChange={(event) => setLongTitle(event.target.value)}
        helperText="Titolo che appare nella lista delle esibizioni nella Home"
      />
      <Typography>Descrizione</Typography>
      <TextField
        value={description.replaceAll("<br />", "\n")}
        onChange={(event) => setDescription(event.target.value)}
        multiline
        minRows={4}
        helperText={
          "La descrizione dell'esibizione apparità nella pagina dedicata all'esibizione, lasciare vuoto se non si intende mostrarlo"
        }
      />
      <Typography variant="h5">Miniatura</Typography>
      <Stack direction="row" gap={2} alignItems="flex-end">
        <img
          src={thumbnailFile ? URL.createObjectURL(thumbnailFile) : thumbnail}
          alt={""}
          style={{
            width: "420px",
            height: "240px",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
        <Stack>
          <div>
            <Button variant="contained" component="label">
              Carica
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(event) => {
                  if (event.target?.files?.[0]) setThumbnailFile(event.target.files[0]);
                }}
              />
            </Button>
          </div>
          <FormHelperText>
            La miniatura dell'esibizione nella Home. <br /> La dimensione deve essere uguale o maggiore di 420x240
            pixels. <br /> Il rapporto d'aspetto verrà adattato in automatico.
          </FormHelperText>
        </Stack>
      </Stack>
      <Typography variant="h5">Galleria</Typography>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <TextField
            label="Colonne"
            value={columns}
            onChange={(e) => {
              const nCols = parseInt(e.target.value);
              setColumns(nCols);
            }}
            type="number"
            inputProps={{
              min: "1",
            }}
          />
          <TextField
            label="Altezza base righe"
            value={height}
            onChange={(e) => setHeight(parseInt(e.target.value))}
            type="number"
            inputProps={{
              min: "1",
            }}
          />
          <Button
            variant="contained"
            onClick={() => setGalleryItems((current) => [...current, { id: uuidv4(), img: "", cols: 1, rows: 1 }])}>
            Aggiungi
          </Button>
        </Stack>
        <ImageList
          sx={{ width: wrapper, overflow: "hidden" }}
          variant="quilted"
          cols={columns}
          rowHeight={height}
          gap={16}>
          {galleryItems.map((item, index) => (
            <ImageListItem
              key={item.id}
              cols={item.cols || 1}
              rows={item.rows || 1}
              sx={{
                position: "relative",
                "& .inner": {
                  display: "none",
                },
                "&:hover": {
                  "& .inner": {
                    display: "block",
                  },
                },
              }}>
              <img
                src={files[item.id] ? URL.createObjectURL(files[item.id]) : item.img}
                alt={""}
                loading="lazy"
                style={{ background: "grey" }}
              />
              <Stack
                className="inner"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: "1",
                }}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}>
                  <Stack
                    borderRadius={1}
                    p={2}
                    sx={{
                      background: "white",
                    }}
                    gap={2}>
                    <TextField
                      label="Colonne"
                      value={item.cols}
                      onChange={(e) => updateGalleryItem(index, { ...item, cols: parseInt(e.target.value) })}
                      type="number"
                      inputProps={{
                        min: "1",
                      }}
                    />
                    <TextField
                      label="Righe"
                      value={item.rows}
                      onChange={(e) => updateGalleryItem(index, { ...item, rows: parseInt(e.target.value) })}
                      type="number"
                      inputProps={{
                        min: "1",
                      }}
                    />
                    <Stack direction="row" gap={1} justifyContent="space-between">
                      <Button variant="contained" component="label">
                        Carica
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(event) => {
                            if (event.target?.files?.[0]) updateGalleryFile(item.id, event.target.files[0]);
                          }}
                        />
                      </Button>
                      <Button onClick={() => removeGalleryItem(index)}>Elimina</Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Stack>
  );
}
