import { Drawer, IconButton, Stack, styled, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { wrapper } from "../../theme";
import useExhibitions from "../../useExhibitions";
import logo from "./logo.webp";
import MenuIcon from "@mui/icons-material/Menu";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:820px)");
  const exhibitions = useExhibitions();

  const [isOpen, setIsOpen] = useState(false);

  const openLink = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (location.pathname !== "/") {
      navigate("/");
      localStorage.setItem("route", id);
    }
    if (isMobile) {
      setIsOpen(false);
      localStorage.setItem("route", id);
    }
  };

  useEffect(() => {
    const id = localStorage.getItem("route");
    if (isMobile && !isOpen && id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [isMobile, isOpen]);

  const scrollbarWidth = useMemo(() => {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();
    return width;
  }, []);

  const links = (
    <>
      <Link onClick={() => openLink("home")}>HOME</Link>
      <Link onClick={() => openLink("services")}>SERVIZI</Link>
      <Link
        onClick={() => openLink("exhibitions")}
        sx={{
          position: "relative",
          zIndex: "1",
          "& .dropdown": {
            display: "none",
            top: 24,
            position: "absolute",
            background: "#ffb200",
            padding: 2,
            width: 240,
          },
          "&:hover": {
            "& .dropdown": {
              display: "flex",
            },
          },
        }}>
        ALLESTIMENTI
        <Stack className="dropdown" gap={2}>
          {exhibitions.map((ex, index) => (
            <Link
              onClick={() => navigate(`/exhibitions/${ex.id}`)}
              key={`ex${index}`}
              sx={{
                "& :hover": {
                  filter: "brightness(0.8)",
                },
              }}>
              {ex.shortTitle}
            </Link>
          ))}
        </Stack>
      </Link>
      <Link onClick={() => openLink("contacts")}>CONTATTI</Link>
    </>
  );

  return (
    <div>
      <Stack
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: `calc(100% - ${scrollbarWidth}px)`,
          background: "#ffb200",
          height: "100px",
          alignItems: "center",
          zIndex: 1400,
        }}>
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            width: wrapper,
          }}>
          <Stack style={{ background: "white" }}>
            <img
              src={logo}
              alt="logo"
              style={{
                padding: 8,
                height: "100px",
                objectFit: "contain",
                border: "8px solid #ffb200",
                borderRight: 0,
                borderLeft: 0,
              }}
            />
          </Stack>
          {isMobile ? (
            <>
              <div style={{ flexGrow: 1 }} />
              <IconButton onClick={() => setIsOpen((c) => !c)}>
                <MenuIcon fontSize="large" style={{ color: "black" }} />
              </IconButton>
              <Drawer
                anchor={"top"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                transitionDuration={0}
                PaperProps={{
                  sx: {
                    background: "#fff",
                    top: 100,
                  },
                }}
                sx={{ zIndex: 1400, top: 100 }}>
                <Stack alignItems="center">
                  <Stack gap={2} width={wrapper} pt={2} pb={2}>
                    {links}
                  </Stack>
                </Stack>
              </Drawer>
            </>
          ) : (
            <Stack
              style={{
                gap: 64,
                flexDirection: "row",
                border: "1px solid black",
                borderLeft: "0px",
                borderRight: "0px",
                padding: "2px 32px",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}>
              {links}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Outlet />
    </div>
  );
}

const Link = styled(Stack)({
  display: "inline-flex",
  color: "black",
  fontSize: "1.125rem",
  fontWeight: "bold",
  cursor: "pointer",
  textDecoration: "none",
  transition: "0.3s color",
  "&:hover": {
    textDecoration: "underline",
  },
});
