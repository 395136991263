import { Alert, Button, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { app } from "../../firebase";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) navigate("/admin");
    });
  }, [navigate]);

  async function signIn() {
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/admin");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setError("Email Invalida");
        } else if (error.code === "auth/user-not-found") {
          setError("Email errata");
        } else if (error.code === "auth/wrong-password") {
          setError("Password errata");
        } else if (error.code === "auth/too-many-requests") {
          setError("Troppi tentativi, riprovare tra 5 minuti");
        } else setError(`Errore sconosciuto: ${error.code}`);
      });
  }

  return (
    <Stack
      style={{
        background: "#e7ebf0",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}>
      <Card sx={{ width: "90%", maxWidth: 400 }}>
        <CardContent>
          <Stack gap={2}>
            <Typography variant="h5">Autenticazione</Typography>
            <div />
            <Typography>Email</Typography>
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
            <Typography>Password</Typography>
            <TextField value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
            <div />
            <Button variant="outlined" onClick={signIn}>
              accedi
            </Button>
            {error ? <Alert severity="error">{error}</Alert> : null}{" "}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
