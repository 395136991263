import { Stack } from "@mui/material";
import { wrapper } from "../../../theme";
import home from "./home.gif";
import background from "./background.jpg";

export default function Home() {
  return (
    <Stack id="home" sx={{ alignItems: "center", paddingTop: "100px" }}>
      <Stack sx={{ width: wrapper }}>
        <img src={home} alt="home" />
      </Stack>
      <div
        style={{
          backgroundImage: `url(${background})`,
          height: "100%",
          width: "100%",
          backgroundAttachment: window.innerWidth < 600 ? "initial" : "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <Stack sx={{ alignItems: "center" }}>
          <Stack sx={{ width: wrapper }}>
            <div
              style={{
                maxWidth: 464,
                fontSize: "1.375rem",
                color: "black",
                background: "#ffb200",
                padding: "16px 32px",
                lineHeight: "40px",
              }}>
              E’ un sistema modulare pensato per risolvere problemi espositivi in contesti complessi dove non è
              possibile utilizzare le pareti, come musei, antiche dimore, porticati, chiostri, cortili e giardini o
              altri spazi inusuali. Le strutture in ferro hanno il vantaggio di essere robuste e al contempo eleganti e
              poco invasive, lasciando al luogo la sua visibilità e alle opere esposte la loro leggibilità. Sono
              collocabili su qualunque tipo di pavimentazione con i dovuti supporti e accorgimenti; sono facilmente
              trasportabili e stoccabili, poiché tutte smontabili. La nostra esperienza nel settore risolverà qualunque
              problematica.
            </div>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
}
