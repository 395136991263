import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { wrapper } from "../../../theme";
import useExhibitions from "../../../useExhibitions";
import SectionTitle from "../SectionTitle";

export default function Exhibitions() {
  const navigate = useNavigate();
  const exhibitions = useExhibitions();
  const isMobile = useMediaQuery("(max-width:475px)");
  const isTablet = useMediaQuery("(max-width:880px)");

  return (
    <>
      <Stack
        sx={{ alignItems: "center", paddingBottom: 8 }}
        id="exhibitions"
        style={{ paddingTop: "100px", marginTop: "-100px" }}>
        <SectionTitle>Allestimenti</SectionTitle>
        <Stack sx={{ width: wrapper, flexDirection: "row", gap: 2, flexWrap: "wrap", paddingBottom: 8 }}>
          {exhibitions.map((ex, index) => (
            <Stack
              onClick={() => navigate(`/exhibitions/${ex.id}`)}
              key={`ex${index}`}
              sx={{
                boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",

                width: isMobile ? "100%" : isTablet ? "calc(50% - 8px)" : "calc(33% - 8px)",
                "& :hover": {
                  filter: "brightness(0.8)",
                },
              }}>
              <img
                src={ex.thumbnail}
                alt={ex.longTitle}
                style={{
                  width: "100%",
                  height: "240px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
              <Typography
                style={{
                  color: "black",
                  fontSize: "1.375rem",
                  textAlign: "center",
                  margin: "16px 0",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: "0 4px",
                }}>
                {ex.longTitle}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Outlet />
    </>
  );
}
