import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD3vrj6NGId64TcIUMOSaBfLyNem_WqTbY",
  authDomain: "gaze-system.firebaseapp.com",
  databaseURL: "https://gaze-system-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gaze-system",
  storageBucket: "gaze-system.appspot.com",
  messagingSenderId: "766350238193",
  appId: "1:766350238193:web:614301b7862885c4cca450",
  measurementId: "G-WQ005VS1GG",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
