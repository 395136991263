import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

export interface GalleryItem {
  id: string;
  img: string;
  cols: number;
  rows: number;
}

export interface Gallery {
  height: number;
  columns: number;
  items: GalleryItem[];
}

export interface Exhibition {
  id: string;
  longTitle: string;
  shortTitle: string;
  thumbnail: string;
  description: string;
  gallery: Gallery;
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
