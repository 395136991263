import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { Exhibition } from ".";
import { app } from "./firebase";

export default function useExhibitions() {
  const [exhibitions, setExhibitions] = useState<Exhibition[]>([]);

  useEffect(() => {
    const db = getDatabase(app);
    const exhibitionsRef = ref(db, "exhibitions");
    onValue(exhibitionsRef, (snapshot) => {
      const data = snapshot.val();
      setExhibitions(
        Object.keys(data)
          .map((id) => ({ id, ...data[id] }))
          .map((ex: any) => ({
            ...ex,
            gallery: {
              ...ex.gallery,
              items: JSON.parse(ex.gallery.items),
            },
          }))
      );
    });
  }, []);

  return exhibitions;
}
