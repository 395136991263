import { Dialog, IconButton, ImageList, ImageListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { wrapper } from "../../../theme";
import Contacts from "../../../components/Contacts/Contacts";
import useExhibitions from "../../../useExhibitions";
import { useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Close } from "@mui/icons-material";

export default function Exhibition() {
  const params = useParams();
  const navigate = useNavigate();
  const exhibitions = useExhibitions();
  const isMobile = useMediaQuery("(max-width:820px)");
  const [selectedGallery, setselectedGallery] = useState<number | null>(null);

  const exhibition = useMemo(
    () => exhibitions.find((exhibition) => exhibition.id === params.id),
    [exhibitions, params.id]
  );

  if (!exhibition)
    return (
      <Dialog
        open={true}
        fullScreen
        hideBackdrop
        sx={{
          top: "100px",
        }}>
        <Typography variant="h1">Esibizione non trovata</Typography>
      </Dialog>
    );

  return (
    <Dialog
      open={true}
      fullScreen
      hideBackdrop
      disablePortal
      sx={{
        top: "100px",
      }}>
      <Stack>
        <Stack sx={{ height: 280, alignItems: "center", justifyContent: "center" }}>
          <Stack alignItems="center" gap={1}>
            <Typography sx={{ color: "black", fontSize: "3rem", textAlign: "center" }}>
              {exhibition.shortTitle}
            </Typography>
            <span style={{ width: "30%", height: 6, background: "black" }} />
          </Stack>
        </Stack>
        {exhibition.description ? (
          <Stack sx={{ alignItems: "center", marginTop: 4 }}>
            <Stack sx={{ width: wrapper }} dangerouslySetInnerHTML={{ __html: exhibition.description }}></Stack>
          </Stack>
        ) : null}

        {isMobile ? (
          <Stack pt={2} alignItems={"center"}>
            <Stack width={wrapper} gap={2}>
              {exhibition.gallery.items.map((item, index) => (
                <img
                  key={item.id}
                  src={item.img}
                  alt={""}
                  loading="lazy"
                  style={{ background: "grey" }}
                  onClick={() => setselectedGallery(index)}
                />
              ))}
            </Stack>
          </Stack>
        ) : (
          <ImageList
            sx={{ width: wrapper, overflow: "hidden", alignSelf: "center" }}
            variant="quilted"
            cols={exhibition.gallery.columns}
            rowHeight={exhibition.gallery.height}
            gap={16}>
            {exhibition.gallery.items.map((item, index) => (
              <ImageListItem
                key={item.id}
                cols={isMobile ? exhibition.gallery.columns : item.cols || 1}
                rows={isMobile ? exhibition.gallery.columns - exhibition.gallery.columns / 2 : item.rows || 1}
                sx={{
                  position: "relative",
                  "& .inner": {
                    display: "none",
                  },
                  "&:hover": {
                    "& .inner": {
                      display: "block",
                    },
                  },
                }}>
                <img
                  src={item.img}
                  alt={""}
                  loading="lazy"
                  style={{ background: "grey", cursor: "pointer" }}
                  onClick={() => setselectedGallery(index)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
        <Dialog
          open={selectedGallery !== null}
          style={{ cursor: "pointer", height: "100vh" }}
          onClose={() => setselectedGallery(null)}
          fullScreen>
          <IconButton
            onClick={() => setselectedGallery(null)}
            style={{ position: "fixed", top: 24, right: 24, zIndex: 10 }}>
            <Close />
          </IconButton>
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            emulateTouch
            selectedItem={selectedGallery ?? 0}
            infiniteLoop>
            {exhibition.gallery.items.map((item) => (
              <img
                key={"gallery-" + item.id}
                src={item.img}
                alt={""}
                loading="lazy"
                style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
              />
            ))}
          </Carousel>
        </Dialog>

        <Stack sx={{ alignItems: "center", margin: "100px 0" }}>
          <Stack
            onClick={() => navigate("/")}
            sx={{
              fontWeight: "bold",
              borderRadius: 5,
              padding: "8px 16px",
              background: "#fff",
              border: "3px solid #323232",
              color: "#323232",
              height: "45px",
              justifyContent: "center",
              alignItems: "center",
              transition: "0.5s all",
              cursor: "pointer",
              zIndex: 1,
              "& :hover": {
                background: "#323232",
                color: "white",
              },
            }}>
            RITORNA AGLI ALLESTIMENTI
          </Stack>
        </Stack>
        <div style={{ position: "relative" }}>
          <Contacts title={false} />
          <div style={{ background: "#ffb200", height: 66, width: "100vw", position: "absolute", bottom: 0 }} />
        </div>
      </Stack>
    </Dialog>
  );
}
