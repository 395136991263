import { ThemeProvider, CssBaseline, Dialog } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { theme } from "./theme";
import Admin from "./routes/Admin/Admin";
import Root from "./routes/Root/Root";
import Exhibition from "./routes/Root/Exhibitions/Exhibition";
import Navbar from "./components/Navbar/Navbar";
import Login from "./routes/Login/Login";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Dialog open={true} fullScreen>
        <Navbar />
      </Dialog>
    ),
    children: [
      {
        path: "/",
        element: <Root />,
        children: [
          {
            path: "exhibitions/:id",
            element: <Exhibition />,
          },
        ],
      },

      {
        path: "/admin",
        element: <Admin />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
