import { Stack, styled, Typography, useMediaQuery } from "@mui/material";
import { wrapper } from "../../../theme";
import SectionTitle from "../SectionTitle";
import montaggio from "./montaggio.png";
import allestimento from "./allestimento.png";
import fissaggio from "./fissaggio.jpg";
import altro from "./altro.webp";
import noleggio from "./noleggio.jpg";

const services = [
  {
    title: "NOLEGGIO E VENDITA",
    description:
      "Il sistema Gazesystem può essere noleggiato o acquistato. Si fanno preventivi specifici per soddisfare ogni esigenza.",
    image: noleggio,
    align: "left",
    contain: false,
  },
  {
    title: "MONTAGGIO",
    description:
      "Ci occupiamo della fase di montaggio e smontaggio degli espositori con una possibilità infinita di forme e percorsi da creare.",
    image: montaggio,
    contain: true,
    align: "left",
  },
  {
    title: "ALLESTIMENTO",
    description:
      "Un servizio di curatela si occupa della messa in luogo delle opere, trovando la soluzione migliore per valorizzare i prodotti e lo spazio.",
    image: allestimento,
    contain: true,
    align: "right",
  },
  {
    title: "FISSAGGIO OPERE",
    description:
      "Le opere vengono appese con opportuni sistemi, stampate su dbond​, su tela e su carta abbiamo sistemi pensati ad hoc.",
    image: fissaggio,
    align: "left",
    contain: true,
  },

  {
    title: "TRASPORTO",
    description: `
    <ol>
      <li>Trasporto espositori in sito.</li>
      <li>Trasporto opere (quadri, fotografie, altro) in casse dedicate.</li>
    </ol>`,
    image: altro,
    contain: false,
    align: "left",
  },
];

export default function Services() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [first, ...others] = services;
  return (
    <>
      <Stack
        id="noleggio"
        sx={{ alignItems: "center", marginBottom: isMobile ? 8 : 16, paddingTop: "150px", marginTop: "-100px" }}>
        <Stack
          sx={{
            width: wrapper,
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
          }}>
          {[first].map((service, index) => (
            <Stack
              key={`service${index}`}
              style={{
                flexDirection: isMobile ? "column" : "row-reverse",
                height: isMobile ? "auto" : 300,
              }}>
              <Stack
                style={{
                  justifyContent: "center",
                  width: isMobile ? "100%" : "50%",
                  background: "#ffb200",
                  padding: "32px",
                  gap: 32,
                  alignItems: service.align === "left" ? "flex-start" : "flex-end",
                }}>
                <Title style={{ textAlign: service.align === "left" ? "left" : "right" }}>{service.title}</Title>
                <Description
                  style={{ textAlign: service.align === "left" ? "left" : "right" }}
                  dangerouslySetInnerHTML={{ __html: service.description }}
                />
              </Stack>
              <Stack style={{ width: isMobile ? "100%" : "50%" }}>
                <img
                  src={service.image}
                  alt=""
                  style={{
                    background: "white",
                    height: "100%",
                    width: "100%",
                    objectFit: service.contain ? "contain" : "cover",
                    boxShadow:
                      "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        id="services"
        sx={{ alignItems: "center", marginBottom: isMobile ? 8 : 16, paddingTop: "50px", marginTop: "-100px" }}>
        <SectionTitle>Servizi</SectionTitle>
        <Stack
          sx={{
            width: wrapper,
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
            background: "#f1f1f1",
          }}>
          {others.map((service, index) => (
            <Stack
              key={`service${index}`}
              style={{
                flexDirection: isMobile ? "column" : index % 2 === 0 ? "row" : "row-reverse",
                height: isMobile ? "auto" : 300,
              }}>
              <Stack
                style={{
                  justifyContent: "center",
                  width: isMobile ? "100%" : "50%",
                  background: "#ffb200",
                  padding: "32px",
                  gap: 32,
                  alignItems: service.align === "left" ? "flex-start" : "flex-end",
                }}>
                <Title style={{ textAlign: service.align === "left" ? "left" : "right" }}>{service.title}</Title>
                <Description
                  style={{ textAlign: service.align === "left" ? "left" : "right" }}
                  dangerouslySetInnerHTML={{ __html: service.description }}
                />
              </Stack>
              <Stack style={{ width: isMobile ? "100%" : "50%" }}>
                <img
                  src={service.image}
                  alt=""
                  style={{
                    mixBlendMode: "multiply",
                    height: "100%",
                    width: "100%",
                    objectFit: service.contain ? "contain" : "cover",
                    boxShadow:
                      "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
}

const Title = styled(Typography)({
  width: "fit-content",
  color: "black",
  fontSize: "1.75rem",
  fontWeight: "bold",
});

const Description = styled(Typography)({
  fontSize: "1.375rem",
});
